class FBTextFieldWithRangeState {
  // MARK: @observables

  // MARK: @config

  // MARK: @constructor

  // MARK: @actions

  // MARK: @helpers
}

export default FBTextFieldWithRangeState;
