import { Box, Button, ButtonBase, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as RemoveDocument } from '../../../../assets/images/trash.svg';
import { ReactComponent as RemoveDocumentActive } from '../../../../assets/images/trashHover.svg';
import { Attachment } from '../../../../state/ducks/attachments/types';
import { documentRevisionsSelectors } from '../../../../state/ducks/documentRevisions';
import { DocumentRevisionStatus } from '../../../../state/ducks/documentRevisions/types';
import Text from '../../../components/Text';
import { CustomStyleTooltip } from '../../../dashboard.new/line.items/common/CustomStyleTooltip';
import { attachmentsDisabledChangeControl } from '../../../document.revision/utils/helpers';
import { documentRevisionStatusContextFromDocList } from '../../../documentRevision/DocumentRevisionsManager.container';
import { useLatestRevisionId } from '../../../hooks/useLatestRevision';
import FileIcon from './icon';
import { styles } from './utils/styles';
import { AttachmentIconProps } from './utils/types';

const AttachmentIconPresenter: React.FunctionComponent<AttachmentIconProps> = ({
  attachment,
  size,
  handleRemove,
  handleContextMenu,
  disabled = false,
  isLoading,
  isUploading,
  isRedLine,
  handleIconClick,
  canDeleteFile,
  isNewVersion,
  documentRevisionData,
  ...other
}) => {
  const file = attachment || {} as Attachment;
  const [isMouseInside, setMouseInside] = useState(false);
  const [removeHover, setRemoveHover] = useState(false);

  const classes = styles({
    size: size || 'md',
    disabled,
  });
  const intl = useIntl();
  const fileSize = useMemo(() => {
    const size = file.fileSizeS3Link;
    const i = size !== 0 ? Math.floor(Math.log(Number(size)) / Math.log(1024)) : 0.00;
    return `${(Number(size) / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  }, [file.fileSizeS3Link]);
  const fileTypeDescription = useMemo(() => {
    const microsoftFiles = ['XLSX', 'XLS', 'DOCX', 'DOC'];
    const type = file.type.toUpperCase();
    if (microsoftFiles.includes(type)) {
      return intl.formatMessage({ id: `file.type.${type}` });
    }

    return intl.formatMessage({ id: 'file.type' }, { type });
  }, [file.type, intl]);
  const isPrimary = file.id === documentRevisionData?.primaryAttachment?.id;

  const isUploadingRenderer = () => (
    <Box>
      <Text translation="common.uploading" />
    </Box>
  );
  const documentRevisionStatusFromDocList = React.useContext(documentRevisionStatusContextFromDocList);

  const docRevById = useSelector(documentRevisionsSelectors.byId);
  const latestReleasedDocRevId = useLatestRevisionId(documentRevisionData, DocumentRevisionStatus.Released);
  const latestDocRevId = useLatestRevisionId(documentRevisionData);
  const attachmentsFromReleased = latestReleasedDocRevId ? docRevById[latestReleasedDocRevId]?.attachments ?? [] : [];
  const isAttachedFromReleased = attachmentsFromReleased.some(releasedAttachment => releasedAttachment.id === attachment?.id);

  const isLastVersionReleased = latestReleasedDocRevId === latestDocRevId;
  const isTransferred = isLastVersionReleased || isNewVersion ? isAttachedFromReleased : false;

  const deleteButtonRenderer = canDeleteFile && (
    <Box
      marginLeft="auto"
      marginRight="10px"
      visibility={isMouseInside ? 'visible' : 'hidden'}
      borderRadius={4}
      onMouseEnter={() => setRemoveHover?.(!disabled)}
      onMouseLeave={() => setRemoveHover?.(false)}
    >
      { !attachmentsDisabledChangeControl(documentRevisionData) && <Button
        className={classes.removeButton}
        onClick={handleRemove && handleRemove(file)}
        id="AttachmentIconPresenter-handleRemove"
      >
        {removeHover ? (
          <CustomStyleTooltip
            title={<Text translation="common.delete" />}
            placement="top"
            arrow
            classes={{
              tooltipPlacementTop: classes.tooltipPlacementTop,
            }}
          >
            <RemoveDocumentActive />
          </CustomStyleTooltip>
        ) : (
          <RemoveDocument />
        )}
      </Button> }
    </Box>
  );

  return (
    <div
      className={classes.attachmentContainer}
      data-cy="attachment"
      onMouseEnter={() => setMouseInside?.(!disabled)}
      onMouseLeave={() => setMouseInside?.(false)}
    >
      <div className={classes.iconContainer}>
        <Tooltip title={`${file.name}.${file.type}`}>
          <ButtonBase
            onContextMenu={handleContextMenu?.(file)}
            key={`icon-${file.id}`}
            onClick={handleIconClick?.(file)}
            id="AttachmentIconPresenter-handleIconClick"
          >
            {isLoading && <CircularProgress className={classes.progress} />}
            {!isLoading
              && <FileIcon
                {...other}
                attachment={attachment}
                extension={file.type}
                isUploading={isUploading}
                isRedLine={isRedLine}
                isPrimary={isPrimary}
                isTransferred={(documentRevisionStatusFromDocList === DocumentRevisionStatus.Draft
                  || documentRevisionStatusFromDocList === DocumentRevisionStatus.PendingChange) ? isTransferred : false}
              />
            }
          </ButtonBase>
        </Tooltip>
      </div>
      <div
        className={classes.fileNameContainer}
        onClick={handleIconClick?.(file)}
        onContextMenu={handleContextMenu?.(file)}
      >
        <Typography
          className={classNames(classes.fileName, {
            [classes.isUploading]: isUploading,
          })}
          variant="caption"
          gutterBottom={true}
          align="left"
          title={`${file.name}.${file.type}`}
          id="AttachmentIconPresenter-fileTitle"
          data-cy="attachment.title"
        >
          {`${file.name}.${file.type}`}
        </Typography>
        <Typography
          variant="caption"
          gutterBottom={true}
          align="left"
          display="block"
        >
          {`${fileSize} ${fileTypeDescription}`}
        </Typography>
      </div>
      {deleteButtonRenderer}
      {isUploading && isUploadingRenderer()}
    </div>
  );
};

export default AttachmentIconPresenter;
