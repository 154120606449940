import { Box } from '@material-ui/core';
import { kebabCase } from 'lodash';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import WarningCell from '../../../../components/common/kendo/WarningCell';
import OverflowTooltip from '../../../../components/OverflowTooltip';
import { documentVersionPath } from '../../../../document.revision/utils/paths';
import { EMPTY_PLACEHOLDER } from '../../constants';
import { DetailsObj, EditableLHRSummaryItem, EditableLHRSummaryItemEditEvent } from '../../types';
import { getDisplayDocument } from '../../utils';
import { ColumnMenuContext } from './CustomColumnMenu';
import { InvalidDocIndicator } from './InvalidDocIndicator';
import { styles } from './styles';

interface props {
  detailsObj?: DetailsObj
  dataItem: EditableLHRSummaryItem
  isRevRemoved?: boolean
  onClick?: (event: EditableLHRSummaryItemEditEvent) => void
  field: string
}

export const PreviewDoc: React.FC<props> = ({
  dataItem,
  detailsObj,
  isRevRemoved,
  onClick,
  field,
}) => {
  const classes = styles();
  const { docId, documentId, displayRevision, value, displayStatus }
    = detailsObj ?? {};
  const dataCy = `${kebabCase(field)}-cell`;
  const { hasErrors } = useContext(ColumnMenuContext);

  if (!documentId && value && hasErrors) {
    return (
      <WarningCell
        onClick={() => onClick?.({ dataItem })}
        documentNumber={value}
        dataCy={dataCy}
      />
    );
  }

  if (!documentId) {
    const fieldValue = value ?? EMPTY_PLACEHOLDER;
    return (
      <OverflowTooltip
        title={fieldValue}
        placement="top"
        interactive
        arrow
        onClick={() => onClick?.({ dataItem })}
        data-cy={dataCy}
      >
        {fieldValue}
      </OverflowTooltip>
    );
  }

  return (
    <Box
      onClick={() => onClick?.({ dataItem })}
      display="flex"
      justifyContent="space-between"
    >
      <Link
        to={documentVersionPath(value ?? '', documentId)}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        data-cy={dataCy}
        className={classes.link}
      >
        <OverflowTooltip
          title={getDisplayDocument(docId, displayRevision)}
          placement="top"
          interactive
          arrow
          data-cy={dataCy}
        >
          {getDisplayDocument(docId, displayRevision)}
        </OverflowTooltip>
      </Link>
      <InvalidDocIndicator status={displayStatus} isRevRemoved={isRevRemoved} dataCy={`${dataCy}-invalid-indicator`} />
    </Box>
  );
};
