import { PrimitiveType } from 'intl-messageformat';
import { createIntl, createIntlCache } from 'react-intl';
import messagesEn from './../ui/translations/en.json';

const messages = {
  en: messagesEn,
};

export const locale = 'en';
export const defaultCurrency = 'USD';

export const currentLocaleMessages = messages[locale];

const cache = createIntlCache();

export const intl = createIntl({ locale, messages: currentLocaleMessages }, cache);

export const translate = (id?: string, values?: Record<string, PrimitiveType>): string =>
  id ? intl.formatMessage({ id }, values) : '';

export const isTranslationExists = (id: string): boolean => id in intl.messages;
