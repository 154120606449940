import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import {
  MomentFormats,
  TIME_ZONE,
} from '../../../../../common/utils/date';
import {
  DatePicker,
  FormikField,
} from '../../../../components/forms/fields-next';
import {
  Mode,
  MODE_FIELD,
} from '../../../../components/KendoDataGrid/constants';
import OverflowTooltip from '../../../../components/OverflowTooltip';
import { LHR_SUMARY_FIELDS } from '../../constants';
import { CustomTemplateProps } from '../../types';

export const DateCell: React.FC<CustomTemplateProps> = ({
  field,
  dataItem,
  onClick,
  PopoverProps,
}) => {
  const isEditMode = [Mode.add, Mode.edit].includes(dataItem[MODE_FIELD]);
  const value = dataItem[field];

  if (!isEditMode) {
    return (
      <OverflowTooltip
        title={value}
        placement="top"
        onClick={() => onClick?.({ dataItem })}
        interactive
        arrow
      >
        {value}
      </OverflowTooltip>);
  }

  const { getFieldProps, setFieldValue } = useFormikContext();
  const removeCustomPrefix = (fieldName: string): string =>
    fieldName.replace(/^custom/, '').replace(/^[A-Z]/, (char) => char.toLowerCase());

  const handleChange = (date: MaterialUiPickersDate) => {
    const dateInPST = date && moment.tz(moment(date).format(MomentFormats.YearMonthDate), TIME_ZONE).startOf('day');
    const dateValue = dateInPST?.utc().format();
    setFieldValue(field, dateValue);
    setFieldValue(removeCustomPrefix(field), dateValue);
  };

  const handleClear = () => {
    setFieldValue(field, null);
    setFieldValue(removeCustomPrefix(field), null);
  };

  return (
    <FormikField name={field}>
      <DatePicker
        {...getFieldProps(field)}
        PopoverProps={PopoverProps}
        size="small"
        data-cy={`${field}`}
        onChange={handleChange}
        onClear={field === LHR_SUMARY_FIELDS.customRecordCreationDate ? undefined : handleClear}
        format={MomentFormats.MonthDateYearTwoDigit}
      />
    </FormikField>
  );
};
