import { Box, Grid, WithStyles, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { flatten, includes, omit, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SM } from '../../../App';
import { LINE_TYPES } from '../../../state/ducks/attachments/constants';
import { DataProps } from '../../../state/ducks/common/types';
import { documentTypeSelectors } from '../../../state/ducks/documentRevisions/documentType';
import { Document, DocumentRevision, DocumentRevisionStatus, FBOutputDocumentType } from '../../../state/ducks/documentRevisions/types';
import { documentsActions } from '../../../state/ducks/documents';
import { RELATED_PARTS_STATUS } from '../../../state/ducks/relatedParts/types';
import { ApplicationState } from '../../../state/reducers';
import { LCP } from '../../administration/general.settings/panels/LCP/components/Grid/types';
import SummaryField from '../../common/summary/base';
import { FormContext } from '../../components/forms/FormContext';
import EquipmentSpecification from '../../documentRevision/forms/presenters/EquipmentSpecification';
import RelatedEquipments, { shouldShowRelatedEquipmentsTable } from '../../documentRevision/forms/presenters/related.equipments';
import RelatedPartsWrapper from '../../documentRevision/forms/presenters/related.parts/RelatedParts.wrap';
import RetrainField from '../../documentRevision/forms/presenters/RetrainField';
import { getHighestRevision } from '../../documentRevision/forms/transform';
import { checkIsDocumentEB, checkIsDocumentOTHER, checkIsDocumentPart, checkIsDocumentPO, checkIsDocumentRecord } from '../../documentRevision/helpers/checkDocumentGroup';
import { isDocumentRevisionInStatus } from '../../documentRevision/helpers/documentRevisionStatus';
import { styles as AutoCompleteStyles } from '../../form.builder/styles/FBAutocomplete.styles';
import { useTraining } from '../../hooks/docCustomization/useCustomization';
import useActionCreator from '../../hooks/useActionCreator';
import useAsync from '../../hooks/useAsync';
import { useScrollIntoView } from '../../hooks/useScrollIntoView';
import Attachment from './attachment';
import BuildsContainer from './builds.container';
import styles from './styles';
import CompleteTraining from './training/complete.training';
import TrainingControl from './training/training.control';

interface OwnProps {
  canShowCreateVersionButton?: boolean
  canChangeOwnerShip?: boolean
}

type Props = OwnProps &
DataProps<DocumentRevision> &
WithStyles<typeof styles>;

const Presenter: React.FunctionComponent<Props> = ({
  classes,
  dataProp,
  dataProp: {
    id,
    document,
    attachments,
    status,
    name,
    retrain,
    referenceTo,
    deprecatedAt,
    trainingRecords,
    formTemplate,
    formDocument,
    parent,
    children,
    childrenParts,
    parentParts,
    displayRevision,
  },
  canChangeOwnerShip,
  canShowCreateVersionButton,
}) => {
  useScrollIntoView();
  const onSubmit = () => undefined;
  const { formInput } = dataProp || {};
  const docTypeId = dataProp?.document?.documentType?.id;
  const docDetails: Partial<DocumentRevision> = {
    docId: document.docId,
    name,
    displayRevision,
  };

  const { isVisible: isTrainingVisible } = useTraining(docTypeId);

  const groupOptions = document?.documentType?.groupOptions;
  const isRecord = checkIsDocumentRecord(groupOptions);
  const isEB = checkIsDocumentEB(groupOptions);
  const isPO = checkIsDocumentPO(groupOptions);
  const isRelatedEquipmentsShowing = shouldShowRelatedEquipmentsTable(dataProp);
  const isAttachmentEmpty = !attachments?.length;
  const { _tabsState } = SM.useStores();
  const [displayChildrenParts, setDisplayChildrenParts] = useState(childrenParts);
  const shouldRenderTrainingButton = isDocumentRevisionInStatus(
    [DocumentRevisionStatus.Released, DocumentRevisionStatus.Approved],
    status);
  const outputDocumentTypeName = useSelector((state: ApplicationState) =>
    formTemplate ? (formTemplate.outputDocumentTypes as FBOutputDocumentType[]).map((i) =>
      documentTypeSelectors.getDocTypeName(state, i.id)).join(', ') : []);

  const isPart = checkIsDocumentPart(groupOptions);
  const isPartForm = useSelector((state: ApplicationState) => formTemplate
    ? checkIsDocumentPart((formTemplate.outputDocumentTypes[0] as FBOutputDocumentType)?.groupOptions) : false);
  const isOther = checkIsDocumentOTHER(groupOptions);
  const isOtherForm = useSelector((state: ApplicationState) => formTemplate
    ? checkIsDocumentOTHER((formTemplate?.outputDocumentTypes[0] as FBOutputDocumentType)?.groupOptions) : false);

  const relatedPartsViewOnly
        = !dataProp?.relatedPartsStatus
        || dataProp?.relatedPartsStatus === RELATED_PARTS_STATUS.PARENT_TOGETHER
        || isDocumentRevisionInStatus(
          [DocumentRevisionStatus.Deprecated, DocumentRevisionStatus.Obsolete, DocumentRevisionStatus.Voided],
          status);

  const renderTrainingControl = shouldRenderTrainingButton && (
    <TrainingControl
      dataProp={trainingRecords}
      documentRevisionId={id}
      docDetails={docDetails}
    />
  );

  const renderSelfTaughtTraining = shouldRenderTrainingButton && (
    <CompleteTraining
      {...{ trainingRecords, id, document, docDetails }}
    />
  );
  const containsRedline = (): boolean => {
    for (const attachment of attachments) {
      if (attachment.lineType === LINE_TYPES.RED_LINE) {
        return true;
      }
    }
    return false;
  };

  const getDocumentById = useActionCreator(documentsActions.getDocumentById);
  const docByIdAsync = useAsync({
    onSuccess: (data?: Document) => {
      const childPart = getHighestRevision(data?.documentRevisions || []);
      if (!childPart) { return; }
      set(childPart, 'document', omit(data, 'documentRevisions'));
      setDisplayChildrenParts([childPart]);
    },
  });

  const shownEquipmentSpecificationLink = Boolean(dataProp?.formInput?.equipmentSpecification);

  useEffect(() => {
    const childrenPartsForRevise = formInput?.childrenPartsForRevise;
    if (!childrenParts?.length && childrenPartsForRevise?.[0]) {
      docByIdAsync.start(getDocumentById, childrenPartsForRevise[0].id, docByIdAsync);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const summaryStyles = {
    labelStyle: { ...AutoCompleteStyles.labelProps, marginBottom: 2 },
    valueStyle: { ...AutoCompleteStyles.labelFont, marginBottom: 22 },
  };

  const lcp = dataProp?.lifecyclePhase as unknown as LCP;
  const contentRenderer = () => (
    <Box className={classes.contentBox}>
      <Grid container>
        {
          isTrainingVisible && !isRecord
          && <RetrainField
            revisionChangeType={formInput?.revisionChangeType}
            isDisabled={true}
            {...{ documentTypeId: docTypeId, isRecord }}
          />
        }
        {(isPart || isPartForm) && (
          <SummaryField
            label="common.lifecycle.phase"
            value={lcp?.displayLabel}
            {...summaryStyles}
          />)}
        {formTemplate && outputDocumentTypeName && (
          <SummaryField
            label="document.output.type"
            value={outputDocumentTypeName}
            {...summaryStyles}
          />
        )}
        {isEB && parent && (
          <BuildsContainer dataProp={flatten([parent])} label="common.previous.build"
            labelProps={AutoCompleteStyles.labelProps}
            labelFont={AutoCompleteStyles.labelFont} docRevId={dataProp.id} />
        )}
        {isEB && children && (
          <BuildsContainer dataProp={children} label="common.next.build"
            labelProps={AutoCompleteStyles.labelProps} labelFont={AutoCompleteStyles.labelFont} />
        )}
        {isPart && parentParts && (
          <BuildsContainer dataProp={flatten([parentParts])} label="common.part.of"
            labelProps={AutoCompleteStyles.labelProps} labelFont={AutoCompleteStyles.labelFont} />
        )}
        {(isPart || isPartForm || isOther || isOtherForm) && (
          <Grid item xs={12}>
            <RelatedPartsWrapper
              viewOnly={relatedPartsViewOnly}
              documentRevision={dataProp}
              relatedPartsStatus= {dataProp?.relatedPartsStatus}
            />
          </Grid>
        )}
        {shownEquipmentSpecificationLink && (
          <Grid item xs={6} className={classes.bottomSpace}>
            <EquipmentSpecification
              relatedDocumentRevision={dataProp}
            />
          </Grid>
        )}
        {isRelatedEquipmentsShowing && (
          <Grid item xs={12}>
            <RelatedEquipments parentDocumentRevision={dataProp} />
          </Grid>
        )}
        {isEB && displayChildrenParts && (
          <BuildsContainer dataProp={flatten([displayChildrenParts])} label="form.builder.part"
            labelProps={AutoCompleteStyles.labelProps} labelFont={AutoCompleteStyles.labelFont} />
        )}
        {isPO || includes(outputDocumentTypeName, 'Purchase Order')
          ? attachments && !_tabsState?.isTabActive('details') && (
            <Attachment
              isAttachmentEmpty={isAttachmentEmpty}
              docRevId={id}
              containsRedline={containsRedline()}
              docRevStatus={status}
              documentRevision={dataProp}
            />
          )
          : attachments && (
            <Attachment
              isAttachmentEmpty={isAttachmentEmpty}
              docRevId={id}
              containsRedline={containsRedline()}
              docRevStatus={status}
              documentRevision={dataProp}
            />
          )}
        {renderTrainingControl}
        {renderSelfTaughtTraining}
      </Grid>
    </Box>
  );

  return (
    <FormContext.Provider value={{ submitOnChange: false }}>
      <Formik
        initialValues={dataProp}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {contentRenderer}
      </Formik>
    </FormContext.Provider>
  );
};

Presenter.displayName = 'DocRevSummaryPresenter';

export default withStyles(styles)(Presenter);
