import { isEmpty } from 'lodash';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import React from 'react';
import { FB, FBFileFieldProps, FBFileFieldState } from '..';
import { calculateTotalFileSize, FILE_UPLOAD_LIMIT, handleFileSizeExceedLimit } from '../../components/common/attachment.field/utils';
import { checkIsDocumentWO } from '../../documentRevision/helpers/checkDocumentGroup';

export const withFBAttachmentField = <T extends FBFileFieldProps>(
  Component: React.FC<T>,
): React.FC<T> => {
  const Comp = ({
    onUploadClick,
    onClick,
    onChange,
    fileFieldState,
    name = '',
    ...props
  }: T) => {
    const { fileUploadState, workspaceState, formState } = FB.useStores();
    const { id } = workspaceState ?? {};
    fileFieldState = FB.useRef<FBFileFieldState>(FBFileFieldState);
    const groupOptions = workspaceState?.document?.document?.documentType?.groupOptions;
    const isWORecord = checkIsDocumentWO(groupOptions) && isEmpty(workspaceState?.document?.formTemplate);

    const fileUploadEl = document.getElementById(`fb-attachment-field-${name}`);

    if (fileUploadEl) {
      fileUploadEl.onclick = registerCancel;
    }

    function registerCancel () {
      document.body.onfocus = removeLock;
    }

    function removeLock () {
      if (fileUploadEl?.nodeValue?.length) { return; }
      setTimeout(() => {
        formState?.unlockField(name);
      }, 1000);
    }

    function handleLockField () {
      if (formState?.isBackdropOpen) { return; }
      if (!id) { return; }
      if (!name) { return; }
      formState?.lockField({
        fields: [{
          formInput: [{
            [name]: true,
          }],
        }],
        documentRevisionId: id,
      }, name);
    }

    onUploadClick = () => {
      handleLockField();
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.files) {
        return;
      }
      const filesToUpload = Array.from(event.currentTarget.files);

      const filesSize = calculateTotalFileSize(filesToUpload);
      if (filesSize > FILE_UPLOAD_LIMIT) {
        const names = filesToUpload.map(file => file.name);
        handleFileSizeExceedLimit(names, filesSize, event);
        return;
      }

      if (workspaceState?.mode !== 'preview' || isWORecord) {
        fileFieldState?.upload(filesToUpload);
      }
    };

    onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const element = event.target as HTMLInputElement;
      element.value = '';
    };

    React.useEffect(() => {
      reaction(
        () => fileFieldState?.data,
        (data) => {
          if (workspaceState?.mode !== 'preview' || isWORecord) {
            fileUploadState?.addFile(data);
          }
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useObserver(() => Component({
      ...(props as T),
      onUploadClick,
      onClick,
      onChange,
      formState,
      loading: fileFieldState?.loading,
      name,
    }));
  };

  return (props: T) => Comp(props);
};
