export const ownerFieldsMapping = {
  'owner.user.name': ['owner.user.avatar', 'owner.user.initialColor'],
};

export const cellOverflowFields = [
  'precalc.partNumberTitle',
  'precalc.partNumberDescription',
  'precalc.shippingItemsPart',
  'precalc.primarySiteContact',
  'precalc.siteAddress',
  'precalc.siteName',
  'formInput.customerName',
];
